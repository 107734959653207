import React, { useContext, useState, useEffect, createContext } from "react";
import axios from "axios";
import PageContext from "./pageContext";

export const APIContext = createContext(null);

export function APIContextProvider({ children }) {
    const { pageId, perPage } = useContext(PageContext);
    const [data, setData] = useState([]);
    let [filter, setFilter] = useState(null);
    const [error, setError] = useState(null);
    const [hasLoaded, sethasLoaded] = useState(false);

    const togglehasLoaded = () => {
        sethasLoaded(current => !current);
    };

    useEffect(() => {
        async function fetchData() {
            axios.get(`/api/blog/getBlogPostIds/${pageId}`)
                .then(response => {
                    let blogPostIds = response.data;
                    let idQueryStrings = blogPostIds.map(id => `id=${id}`).join('&');
                    return axios.get(`/api/blog/getBlogPostContent?${idQueryStrings}`);
                }).then(response => {
                    setData(response.data);
                    togglehasLoaded();
                })
                .catch(error => {
                    setError(error)
                    console.log(error.response)
                    togglehasLoaded();
                });
        }
        fetchData();
    }, []);

    return (
        <APIContext.Provider
            value={{
                data: data,
                hasLoaded: hasLoaded,
                perPage: perPage,
                filter: filter,
                error: error
            }}>
            {children}
        </APIContext.Provider>
    );
}
