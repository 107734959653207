import React, { useContext, useState } from "react";
import Container from './Container';
import { APIContextProvider } from "../utils/apiContext";
import PageContext from '../utils/pageContext';

export default function App({ perPage, pageId }) {
    const [filter, setFilter] = useState("");
    const [currentPage, setCurrentPage] = useState(1);

    return (
        <PageContext.Provider value={{ pageId: pageId, perPage: perPage, filter: filter, setFilter, currentPage: currentPage, setCurrentPage }}>

            <APIContextProvider>
                <Container />
            </APIContextProvider>

        </PageContext.Provider>
    );
}



