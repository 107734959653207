import React, { useState, useContext, useEffect } from 'react';
import { APIContext } from '../utils/apiContext'
import PageContext from '../utils/pageContext';
import useQueryString from '../utils/useQueryString';


export default function Filters() {
    let { data } = useContext(APIContext);
    const { filter, setFilter, currentPage, setCurrentPage } = useContext(PageContext);
    const [availableFilters, setAvailableFilters] = useState([]);
    const getFiltersFromAPI = Array.from(new Set(data.map(item => item.tags).flat(1))).sort();
    const [query, setQuery] = useQueryString("filter", "");


    const updateFilter = e => {
        setFilter(e.target.value)
        //TODO: Remove filter query from URL on Clear Filter
        setQuery(e.target.value)

    };

    useEffect(() => {
        setAvailableFilters(getFiltersFromAPI)
        setCurrentPage(1);
        setFilter(query);
    }, [data.length, filter]);


    return (
        <>
            {availableFilters.length > 0 ?
                <div className="blog-list-sidebar">
                    <div className="flex-between flex-wrap">
                        <h2 className="h5-style"> Filter By Tag </h2>
                        <button className="filter small clear" value="" onClick={updateFilter}> Clear All </button>
                    </div>
                    <ul className="blog-list-sidebar-filters">
                        {availableFilters.map((filterName) => <li> <button className={`filter bold ${(filterName == filter ? 'active' : '')}`} onClick={updateFilter} value={filterName}>{filterName}</button></li>)}
                    </ul>

                </div>
                :
                ""
            }
        </>
    );
}