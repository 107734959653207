import React, { useState, useMemo, useContext, useEffect } from 'react';
import Pagination from '../common/pagination';
import { APIContext } from '../utils/apiContext'
import { LoadingSpinner } from '../common/loadingSpinner';
import PageContext from '../utils/pageContext';
import Post from '../components/Post';

export default function Results() {

    const [results, setResults] = useState([]);
    const { data, hasLoaded, perPage, error } = useContext(APIContext);
    const { filter, currentPage, setCurrentPage } = useContext(PageContext);

    const sortedData = data.sort((a, b) => {
        const aDate = new Date(a.date);
        const bDate = new Date(b.date);

        return aDate > bDate ? -1
             : bDate > aDate ? 1
             : 0;
    });
    
    const filteredData = filter != "" ? sortedData.filter(item => item.tags.includes(filter)) : sortedData;

    let PageSize = parseFloat(perPage);

    const resultsCount = perPage > filteredData.length ? filteredData.length : perPage;

    let getResults = ((useMemo) => {
        const firstPageIndex = (currentPage - 1) * PageSize;
        const lastPageIndex = firstPageIndex + PageSize;
        return filteredData.slice(firstPageIndex, lastPageIndex);
    });

    useEffect(() => {
        setResults(getResults)
    }, [filteredData.length, data, currentPage, hasLoaded, filter, error]);


    return (
        <div className="blog-list-items">
            {!hasLoaded ? <LoadingSpinner /> :
                <>
                    <p className="blog-list-results small light"> Total Results: {filteredData.length} </p>
                    {error === null ?
                        <>
                            {filteredData.length > 0 ?
                                <>
                                    <ul>
                                        {results && results.map(post => (
                                            <Post post={post}/>
                                        ))}
                                    </ul>
                                    <Pagination
                                        className="pagination-bar"
                                        currentPage={currentPage}
                                        totalCount={filteredData.length}
                                        pageSize={PageSize}
                                        onPageChange={page => setCurrentPage(page)}
                                    />
                                </>
                                : <h2 className="h3-style"> No results found. </h2>
                            }
                        </>
                        : <h2 className="h3-style"> An error occurred.
                        </h2>
                    }
                </>
            }

        </div>
    );
}
