import React from 'React';

export function LoadingSpinner() {

    return (
        <div className="spinner-container">
            <div className="loading-spinner">
            </div>
        </div>
    );
}