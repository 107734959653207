import React, { useContext, useState, useEffect, createContext } from "react";
import Filters from './Filters';
import Results from "./Results";


export default function Container() {

    return (

        <div className="blog-list-container">
            <Filters />
            <Results />
        </div>

    );
}