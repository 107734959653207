import { createContext } from "react";


const PageContext = createContext({
    pageId: "",
    perPage: "",
    filter: "",
    setFilter: (filter) => (filter),
    currentPage: 1,
    setCurrentPage: (currentPage) => (currentPage)
});

export default PageContext;
