import React, { useState, useMemo, useContext, useEffect } from 'react';


export default function Post(result) {

    const post = result.post

    return (

        <li className="blog-list-item" key={post.id}>
            <div className="blog-list-item-image-container">
                <div className="blog-list-item-image">
                    <a href={post.link} className=""> {post.title}
                        <img src={post.imageUrl} alt={post.imageAltText} />
                    </a>
                </div>
            </div>

            <div className="blog-list-item-content">
                <div className="flex-between flex-wrap">
                    <span className="h6-style upcoming-event-details-category">{post.author} </span>
                    <p className="h6-style">{post.date}</p>
                </div>

                <h3 className="blog-list-item-title">
                    <a href={post.link} className=""> {post.title}</a>
                </h3>

                <div className="blog-list-item-description" dangerouslySetInnerHTML={{ __html: post.previewContent }} />
            </div>
        </li>


    );
}
