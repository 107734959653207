import React from 'react';
import { usePagination, DOTS } from '../utils/usePagination';


const Pagination = props => {

    const { onPageChange, totalCount, siblingCount, currentPage, pageSize } = props;
    const paginationRange = usePagination({ currentPage, totalCount, siblingCount, pageSize });

    if (currentPage === 0 || paginationRange.length < 2) {
        return null;
    }

    const onNext = (disabled: boolean = false) => {
        if (disabled) return;

        onPageChange(currentPage + 1);
    };

    const onPrevious = (disabled: boolean = false) => {
        if (disabled) return;
        onPageChange(currentPage - 1);
    };

    let dotsCount = 0;

    let lastPage = paginationRange[paginationRange.length - 1];


    return (
        <ul className='pagination-container splide__arrows'>
            {/* Left navigation arrow */}
            <li>
                <button
                    type='button'
                    className={`pagination-button un-button pagination-button-left ${(currentPage === 1 ? 'disabled' : '')}`}
                    onClick={() => onPrevious(currentPage === 1)}
                    disabled={currentPage === 1}
                    data-testid='arrow-left'>

                    <span className="visually-hidden">Previous</span>
                    <svg className="svg-icon">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-arrow-left" />
                    </svg>
                </button>
            </li>
            {paginationRange.map(pageNumber => {

                if (pageNumber === DOTS) {
                    return <li key={pageNumber + ++dotsCount} className="pagination-item dots">&#8230;</li>;
                }

                // Pages
                return (
                    <li key={pageNumber}>
                        <button
                            type='button'
                            className={`pagination-item un-button ${(pageNumber === currentPage ? 'selected' : '')}`}
                            onClick={() => onPageChange(pageNumber)}>
                            {pageNumber}
                        </button>
                    </li>
                );
            })}
            {/*  Right Navigation arrow */}
            <li>
                <button
                    type='button'
                    className={`pagination-button un-button pagination-button-right ${(currentPage === lastPage ? 'disabled' : '')}`}
                    onClick={() => onNext(currentPage === lastPage)}
                    disabled={currentPage === lastPage}
                    data-testid='arrow-right'>
                    <span className="visually-hidden">Previous</span>
                    <svg className="svg-icon">
                        <use xmlnsXlink="http://www.w3.org/1999/xlink" xlinkHref="#icon-arrow-right" />
                    </svg>
                </button>
            </li>
        </ul>
    );
};

export default Pagination;
