import * as React from "react";
import * as ReactDOM from "react-dom";
import App from "./components/App"

const entry = document.getElementById('mainEntry');

if (entry && entry.getAttribute("data-page-id")) {
    const pageId = entry.getAttribute("data-page-id") ? entry.getAttribute("data-page-id") : null;
    const pageSize = entry.getAttribute("data-page-size") ? entry.getAttribute("data-page-size") : 10;
    ReactDOM.render(<App perPage={pageSize} pageId={pageId} />, entry);
}